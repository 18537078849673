/* eslint-disable no-console */
// This is our custom debug plugin for getanalytics.io
import { REQUIRED_EVENT_FIELDS } from '../constants/analytics'
import isLoggerValid from './utils/isLoggerValid'

const checkRequiredFields = (payload) => {
  Object.values(REQUIRED_EVENT_FIELDS).forEach((field) => {
    if (!payload.properties[field]) {
      console.error(
        'Missing required field on analytics call: ',
        field,
        payload.properties
      )
    }
  })
}

const analyticsDebug = ({ options = {} }) => {
  const logger = isLoggerValid(options.logger) ? options.logger : console

  // return object for analytics to use
  return ({
    identify: ({ payload }) => {
      logger?.info('ANALYTICS :: debug :: identify', payload)
    },

    /* Everything else below this is optional depending on your plugin requirements */
    initialize: ({ config }) => {
      logger?.info('ANALYTICS :: debug :: initialize', config)
    },

    loaded: () => true,
    /* All plugins require a name */
    name: 'debug-plugin',
    page: ({ payload }) => {
      checkRequiredFields(payload)
      logger?.info(`ANALYTICS :: debug :: page - ${payload?.properties?.page_name} - Page Loaded`, payload)
    },
    track: ({ payload }) => {
      checkRequiredFields(payload)
      logger?.info(`ANALYTICS :: debug :: track - ${payload?.properties?.page_name} - ${payload?.event}`, payload)
    }
  })
}

export { analyticsDebug }
