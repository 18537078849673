const waitFor = (
  test,
  successCallback,
  failureCallback = () => {},
  count = 0
) => {
  const MAX_COUNT = 20
  const MILLISECONDS_BETWEEN_RETRIES = 100

  const callMyself = (updatedCount) => () => {
    waitFor(test, successCallback, failureCallback, updatedCount)
  }

  if (test()) {
    successCallback()
  } else if (count < MAX_COUNT) {
    setTimeout(callMyself(count + 1), MILLISECONDS_BETWEEN_RETRIES)
  } else {
    failureCallback()
  }
}

export { waitFor }
