/* eslint-disable camelcase */
import { extractApplicationData } from './extractApplicationData'
import { extractEventData } from './extractEventData'
import { extractExperiments } from './extractCommonPayload'
import { extractIds } from './extractIds'
import { extractMetaTags } from './extractMetaTags'
import { extractUrlData } from './extractUrlData'
import { extractUserConsent } from './extractUserConsent'
import { extractUserDeviceData } from './extractUserDeviceData'
import { v4 as uuidv4 } from 'uuid'
import { addHashedData } from './addHashedData'
import { getFormData } from './extractFormData'
import { getTealiumIds } from './getTealiumIds'

const generateFreedomPayload = async (
  payload,
  eventName,
  eventAction,
  { application, shouldExtractApplicationData }
) => {
  const applicationData = {
    page_name: payload?.properties?.page_name,
    ...(shouldExtractApplicationData
      ? extractApplicationData(payload)
      : payload?.properties?.application_data)
  }

  const hashedData = await addHashedData(applicationData)

  // Adding form related fields
  let freedomFormData = {}
  if (payload?.properties?.form) {
    ({ freedomFormData } =
      await getFormData(payload?.properties?.form))
  }

  const appData = { ...applicationData, ...hashedData }
  const extractedId = await extractIds(payload)
  const tealiumData = await getTealiumIds()
  const app_event_horizon_version =
    (typeof process !== 'undefined' && process?.env?.VERSION) || '0.0.0'

  return {
    ...extractEventData(payload, application),
    app_event_horizon_version,
    application_data: appData,
    application_data_string: JSON.stringify(appData),
    event_action: eventAction,
    event_datetime: payload.meta.ts,
    event_id: uuidv4(),
    event_name: eventName,
    experiments: extractExperiments(payload),
    form: freedomFormData,
    ids: { ...extractedId, ...tealiumData },
    meta_tags: {
      ...extractMetaTags(payload)
    },
    page_view_id: payload.properties.page_view_id,
    time_since_last_event: payload.properties.time_since_last_event,
    url_data: {
      ...extractUrlData(payload)
    },
    user_consent: {
      ...extractUserConsent(payload)
    },
    user_device_data: {
      ...extractUserDeviceData(payload)
    }
  }
}

export { generateFreedomPayload }
