import { addHashedData } from './addHashedData'
import { extractCommonPayload } from './extractCommonPayload'
import { pick, omit } from 'ramda'
import { getFormData } from './extractFormData'
import { extractIds } from './extractIds'

const generateTealiumPayload = async (application, fields, payload, type) => {
  const props = payload?.properties
  const allData = props?.application_data
  const applicationData = fields ? pick(fields, allData) : allData
  const pageName = props?.page_name
  const eventAction = type === 'page' ? 'page_view' : props?.event_action
  const eventName = type === 'page' ? 'Page Loaded' : payload.event

  // Adding form related fields
  let tealiumFormData = {}
  if (payload?.properties?.form) {
    ({ tealiumFormData } =
      await getFormData(payload?.properties?.form) || {})
  }

  const hashedData = await addHashedData(applicationData)
  const appData = { ...applicationData, ...hashedData }
  const extractedId = await extractIds(payload)

  return {
    ...omit(['form_email', 'form_phone'], appData),
    ...extractCommonPayload(payload, application),
    ...omit(['auth0_email'], extractedId),
    event_action: eventAction,
    event_name: `${pageName} - ${eventName}`,
    ...tealiumFormData,
    page_view_id: payload.properties.page_view_id,
    tealium_event: eventAction,
    time_since_last_event: payload.properties.time_since_last_event
  }
}

export { generateTealiumPayload }
