import { pick, either, prop } from 'ramda'
import { parseCookies } from 'utils/cookies'

import { EVENT_FIELDS } from '../../constants/analytics'
import { convertProfileToAnalyticsPayload } from './pageUtils'
import { hashEmail } from './hashUtils'
import { setBrowserId } from './setBrowserId'

const extractIds = async (payload) => {
  const cookies = parseCookies()

  const leadId = either(
    prop('leadUUID'),
    prop('id')
  )(payload.properties.profile)

  const profile = convertProfileToAnalyticsPayload(payload.properties.profile)

  const picked = pick(
    [EVENT_FIELDS.CEREBRO_PID, EVENT_FIELDS.FDR_APPLICANT_ID],
    profile
  )

  let auth0EmailHashed
  if (payload?.properties?.ids?.auth0_email) {
    auth0EmailHashed = await hashEmail(payload.properties.ids.auth0_email)
  }

  const cerebroId =
    picked[EVENT_FIELDS.CEREBRO_PID] || payload.properties.cerebro_id

  const browserId = setBrowserId()

  const visitId = payload.properties.ids?.visit_id

  return {
    ...picked,
    ...(cerebroId && { [EVENT_FIELDS.CEREBRO_PID]: cerebroId }), // don't add if undefined
    [EVENT_FIELDS.CLIENT_ID]: payload?.properties?.ids?.client_id,
    [EVENT_FIELDS.GA_CLIENT_ID]: cookies._ga,
    [EVENT_FIELDS.GA_SESSION_ID]: cookies._gid,
    [EVENT_FIELDS.LEAD_UUID]: leadId || payload.userId || payload.anonymousId,
    [EVENT_FIELDS.SESSION_ID]: payload?.properties?.ids?.session_id,
    [EVENT_FIELDS.BROWSER_ID]: browserId,
    [EVENT_FIELDS.AUTH0_EMAIL]: payload?.properties?.ids?.auth0_email,
    [EVENT_FIELDS.AUTH0_EMAIL_HASH]: auth0EmailHashed || undefined,
    [EVENT_FIELDS.AUTH0_USER_ID]: payload?.properties?.ids?.auth0_user_id,
    [EVENT_FIELDS.PERSON_ID]: payload?.properties?.ids?.person_id,
    [EVENT_FIELDS.PRODUCT_INTERACTION_ID]:
      payload?.properties?.ids?.product_interaction_id,
    [EVENT_FIELDS.PROFILE_ID]: payload?.properties?.ids?.profile_id,
    [EVENT_FIELDS.VISIT_ID]: visitId
  }
}

export { extractIds }
