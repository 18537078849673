const getUtmParams = (search) => {
  const allSearchEntries = new URLSearchParams(search).entries()
  const utmParams = {}
  for (const [key, value] of allSearchEntries) {
    if (key.startsWith('utm_')) {
      utmParams[key] = value
    }
  }

  return utmParams
}

export { getUtmParams }
