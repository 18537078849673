import { setCookieValue, parseCookies } from 'utils/cookies'
import { timeCalculation } from '../timeOnPage/timeOnPage'
import { v4 as uuidv4 } from 'uuid'

const EH_VISIT_ID = 'eh_visit_id'
const EH_VISIT_TS = 'eh_visit_ts'
const timeThreshold = 30 * 60 // in second

const getVisitId = () => {
  // Once the user tranfer to a new domain we will have to get
  // `visitId` from the URL or datalayer or somewhere else. TBD
  // For now we will use sessionStorage

  const cookies = parseCookies()
  const visitId = cookies.eh_visit_id || null
  if (visitId) {
    const currentTime = new Date()
    const visitIdSetTime = new Date(cookies.eh_visit_ts) || null
    const timeDifference = timeCalculation(currentTime, visitIdSetTime)
    setCookieValue(EH_VISIT_TS, currentTime, {
      sameSite: 'strict'
    })

    if (timeDifference > timeThreshold) {
      // The visit has expired
      const newVisitId = uuidv4()
      setCookieValue(EH_VISIT_ID, newVisitId, {
        sameSite: 'strict'
      })
      return newVisitId
    }
    return visitId
  } else {
    // This is the first time we are landing on the site
    const firstVisitId = uuidv4()
    setCookieValue(EH_VISIT_ID, firstVisitId, {
      sameSite: 'strict'
    })
    setCookieValue(EH_VISIT_TS, new Date(), {
      sameSite: 'strict'
    })
    return firstVisitId
  }
}

export { getVisitId }
