const US_COUNTRY_CODE = 1

export const hashEmail = async (email) => {
  if (!email) {
    return null
  }
  const formattedEmail = email.trim().toLowerCase()
  const result = await digestMessage(formattedEmail)
  return result
}

export const hashPhone = async (phone) => {
  if (!phone) {
    return null
  }

  const formattedNumber = `${US_COUNTRY_CODE}${phone.replace(/[^.0-9]/g, '')}`
  const result = await digestMessage(formattedNumber)
  return result
}

export const digestMessage = async (message) => {
  const msgUint8 = new TextEncoder().encode(message) // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8) // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string

  return hashHex
}
