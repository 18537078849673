import { curry, reduce, keys } from 'ramda'

const mapKeys = curry((fn, obj) =>
  reduce(
    (acc, key) => {
      acc[fn(key)] = obj[key]
      return acc
    },
    {},
    keys(obj)
  )
)

export { mapKeys }
