import { pick } from 'ramda'
import { EVENT_FIELDS } from '../../constants/analytics'

const extractUserConsent = (payload) => {
  if (!payload.properties.user_consent) {
    return undefined
  }
  return pick(
    [
      EVENT_FIELDS.CONSENT_ID,
      EVENT_FIELDS.CONSENT_ID_SOURCE,
      EVENT_FIELDS.CONSENT_MEDIA,
      EVENT_FIELDS.CONSENT_SCOPE,
      EVENT_FIELDS.CONSENT_TEXT
    ],
    payload.properties.user_consent
  )
}
export { extractUserConsent }
