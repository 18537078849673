import { pick } from 'ramda'
import { EVENT_FIELDS } from '../../constants/analytics'

import { convertProfileToAnalyticsPayload } from './pageUtils'

const extractApplicationData = (payload) => {
  const payloadData = pick(
    [
      EVENT_FIELDS.ACTUAL_DEBT,
      EVENT_FIELDS.CAMPAIGN,
      EVENT_FIELDS.CLICK_URL,
      EVENT_FIELDS.CTA_TEXT,
      EVENT_FIELDS.CTA_VALUE,
      EVENT_FIELDS.PAGE_NAME
    ],
    payload.properties
  )

  const profile = convertProfileToAnalyticsPayload(payload.properties.profile)
  const profileData = pick(
    [
      EVENT_FIELDS.ACTUAL_DEBT,
      EVENT_FIELDS.CAMPAIGN,
      EVENT_FIELDS.COAPP_CREDIT_PULL_ATTEMPTS_REMAINING,
      EVENT_FIELDS.CREDIT_PULL_ATTEMPTS_REMAINING,
      EVENT_FIELDS.CREDIT_PULL_ERROR,
      EVENT_FIELDS.CREDIT_REPORT_ID,
      EVENT_FIELDS.CTA_TEXT,
      EVENT_FIELDS.CTA_VALUE,
      EVENT_FIELDS.FDR_ELIGIBLE,
      EVENT_FIELDS.FORM_ADDRESS,
      EVENT_FIELDS.FORM_CITY,
      EVENT_FIELDS.FORM_COAPP_DATE_OF_BIRTH,
      EVENT_FIELDS.FORM_COAPP_FIRST_NAME,
      EVENT_FIELDS.FORM_COAPP_LAST_NAME,
      EVENT_FIELDS.FORM_DATE_OF_BIRTH,
      EVENT_FIELDS.FORM_DAYS_BEHIND,
      EVENT_FIELDS.FORM_EMAIL,
      EVENT_FIELDS.FORM_EMAIL_HASH,
      EVENT_FIELDS.FORM_ESTIMATED_DEBT_INITIAL,
      EVENT_FIELDS.FORM_FIRST_NAME,
      EVENT_FIELDS.FORM_IDENTITY_QUESTIONS_ANSWERED,
      EVENT_FIELDS.FORM_LAST_NAME,
      EVENT_FIELDS.FORM_PAYMENT_DATE,
      EVENT_FIELDS.FORM_PHONE,
      EVENT_FIELDS.FORM_PHONE_HASH,
      EVENT_FIELDS.FORM_SOCIAL_SECURITY,
      EVENT_FIELDS.FORM_STATE,
      EVENT_FIELDS.FORM_ZIPCODE,
      EVENT_FIELDS.LENDAGE_ELIGIBLE,
      EVENT_FIELDS.PAGE_NAME,
      EVENT_FIELDS.SUIP_ELIGIBLE
    ],
    profile
  )

  const data = {
    ...profileData,
    ...payloadData
  }

  return data
}

export { extractApplicationData }
