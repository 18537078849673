export default function isLoggerValid (logger) {
  if (!logger) {
    return false
  }

  if (!logger.info || typeof logger.info !== 'function') {
    // eslint-disable-next-line no-console
    console.warn('The provided logger does not have an info method available. Reverting to console for debug logs.')
    return false
  }

  return true
}
