const profileFields = {}

const addField = (name, value) => {
  profileFields[name] = value
}

// TODO - Need to refactor this a bit. Sonar didn't like the duplication with eventFields,
//        profileFields, and ulsFields. This is a temporary hack to get around it.
//        There is likely a much better consolidated mapping we can create anyways.
addField('ACTUAL_DEBT', 'actualDebt')
addField('ADDRESS', 'address')
addField('BEHIND_PAYMENT', 'behindPayment')
addField('CEREBRO_PID', 'cerebroPID')
addField('CITY', 'city')
addField(
  'COAPP_CREDIT_PULL_ATTEMPTS_REMAINING',
  'coappCreditPullAttemptsRemaining'
)
addField('COAPP_DATE_OF_BIRTH', 'coappDateOfBirth')
addField('COAPP_FIRST_NAME', 'coappFirstName')
addField('COAPP_LAST_NAME', 'coappLastName')
addField('CREDIT_PULL_ATTEMPTS_REMAINING', 'creditPullAttemptsRemaining')
addField('CREDIT_REPORT_ID', 'creditReportId')
addField('CURRENT_OR_PAST_DUE', 'currentOrPastDue')
addField('DATE_OF_BIRTH', 'dateOfBirth')
addField('DEBT_TYPES', 'debtTypes')
addField('EMAIL', 'email')
addField('ESTIMATED_DEBT', 'estimatedDebt')
addField('FDR_APPLICANT_ID', 'fdrApplicantId')
addField('FDR_ELIGIBLE', 'isEligibleToFdr')
addField('FIRST_NAME', 'firstName')
addField('IDENTITY_ANSWERED', 'identityAnswered')
addField('LAST_NAME', 'lastName')
addField('LENDAGE_ELIGIBLE', 'lendageEligible')
addField('PAYMENT_DATE', 'paymentDate')
addField('PHONE', 'phone') // <- This doesn't match up with ULS 'dayPhone'
addField('SOCIAL_SECURITY', 'ssn')
addField('STATE', 'state')
addField('ESTIMATED_CREDIT', 'estimatedCredit')
addField('SUIP_ELIGIBLE', 'isSuipEligible')
addField('ZIPCODE', 'zip')
addField('DEBT_TYPES', 'debtTypes')

export default profileFields
