import { EVENT_FIELDS } from 'browser/constants/analytics'
import { parseCookies, setCookieValue } from 'utils/cookies'
import { v4 as uuidv4 } from 'uuid'

const setBrowserId = () => {
  const { browser_id: browserId } = parseCookies()

  if (browserId) {
    return browserId
  }

  const newBrowserId = uuidv4()

  setCookieValue(EVENT_FIELDS.BROWSER_ID, newBrowserId, {
    maxAge: 36525 * 24 * 60 * 60,
    sameSite: 'strict'
  })

  return newBrowserId
}

export { setBrowserId }
