// This is our custom Tealium Plugin for getanalytics.io

import { waitFor } from '../utils/waitFor'
import isLoggerValid from '../utils/isLoggerValid'
import { generateTealiumPayload } from '../utils/generateTealiumPayload'

const utag = () => window.utag

const analyticsTealium = ({
  userConfig,
  application,
  includedFields,
  options = {}
}) => {
  const logger = isLoggerValid(options.logger) ? options.logger : console

  return {
    config: Object.assign({}, userConfig),
    name: 'analytics-tealium',
    page: async ({ payload }) => {
      let uTagResolver
      const uTagPromise = new Promise((resolve) => { uTagResolver = resolve })

      waitFor(utag, async () => {
        const payloadToSend = await generateTealiumPayload(
          application,
          includedFields,
          payload,
          'page'
        )

        const uTagCallBack = () => {
          uTagResolver()
          logger.info(
            'ANALYTICS :: tealium :: page - ',
            payloadToSend?.event_name,
            payloadToSend
          )
        }

        utag().view(payloadToSend, uTagCallBack)
      })

      return uTagPromise
    },
    track: async ({ payload, instance }) => {
      let uTagResolver
      const uTagPromise = new Promise((resolve) => { uTagResolver = resolve })

      // Track events don't have pageData by default, so we have to merge it in
      const pageDataProperties = instance.getState('page')?.last?.properties
      payload.properties = {
        ...pageDataProperties,
        ...payload?.properties
      }

      waitFor(utag, async () => {
        const payloadToSend = await generateTealiumPayload(
          application,
          includedFields,
          payload,
          'track'
        )

        const uTagCallBack = () => {
          uTagResolver()
          logger.info(
            'ANALYTICS :: tealium :: track - ',
            payloadToSend?.event_name,
            payloadToSend
          )
        }

        utag().link(payloadToSend, uTagCallBack)
      })

      return uTagPromise
    }
  }
}

export { analyticsTealium }
