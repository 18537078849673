import {
  getOriginalSource,
  getOriginalLandingPage
} from 'analytics-plugin-original-source'
import { mapKeys } from 'utils/mapKeys'
import { pick, map } from 'ramda'
import { getUtmParams } from './getUtmParams'
import { EVENT_ORIGIN } from 'browser/constants/analytics'

const extractCommonPayload = (payload, application) => {
  const originalSources = mapKeys(
    (key) => 'original_utm_' + key,
    getOriginalSource()
  )

  const whitelistedProperties = pick(
    [
      'campaign',
      'cerebro_id',
      'cta_text',
      'cta_value',
      'click_url',
      'event_id',
      'event_type',
      'page_name'
    ],
    payload.properties
  )

  return {
    ...whitelistedProperties,
    application,
    browser_height: payload.properties.height,
    browser_width: payload.properties.width,
    event_datetime: payload.meta.ts,
    event_origin: payload.properties.event_origin || EVENT_ORIGIN.CLIENT,
    event_type: payload.type,
    meta_title: payload?.properties?.title,
    original_landing_page: getOriginalLandingPage(),
    url: payload.properties.url,
    url_hash: payload.properties?.hash,
    url_path: payload.properties?.path,
    url_search: payload.properties?.search,
    user_agent: navigator.userAgent,
    ...getUtmParams(payload.properties?.search),
    ...originalSources
  }
}

export const stringifyAdditionalInfo = experiment => {
  if (experiment.additional_info) {
    return {
      ...experiment,
      additional_info: typeof experiment.additional_info !== 'string' ? JSON.stringify(experiment.additional_info) : experiment.additional_info
    }
  }

  return experiment
}

export const extractExperiments = (payload) => {
  const experiments = payload.properties.experiments
  if (!experiments || !Array.isArray(experiments)) {
    return experiments
  }

  return map(stringifyAdditionalInfo)(experiments)
}

export { extractCommonPayload }
