import { v4 as uuidv4 } from 'uuid'

const EH_EVENT_TIME = 'eh_event_time'
const EH_PAGE_VIEW_ID = 'eh_page_view_id'
const EH_PREVIOUS_PAGE_VIEW_ID = 'eh_previous_page_view_id'
const EH_PAGE_NAME = 'eh_page_name'

// These are values sent in the payload
const PAGE_VIEW_ID = 'page_view_id'
const TIME_SINCE_LAST_EVENT = 'time_since_last_event'

export const getSessionStorageData = (key) => window.sessionStorage.getItem(key)
export const setSessionStorageData = (key, value) =>
  window.sessionStorage.setItem(key, value)

const timeOnPage = (eventType, pageName) => {
  // Determine event type `page` or `track`
  if (!eventType || !pageName) {
    return { [PAGE_VIEW_ID]: undefined, [TIME_SINCE_LAST_EVENT]: undefined }
  }

  const eventTime = new Date(getSessionStorageData(EH_EVENT_TIME)) || null

  const currentPageName = getSessionStorageData(EH_PAGE_NAME) || null
  const pageId = getSessionStorageData(EH_PAGE_VIEW_ID) || null

  let timeDifference

  if (eventType === 'page') {
    // Check to see if we have landed on site the first time
    if (!pageId) {
      const newPageViewId = uuidv4()
      setSessionStorageData(EH_EVENT_TIME, new Date())
      setSessionStorageData(EH_PAGE_VIEW_ID, newPageViewId)
      setSessionStorageData(EH_PAGE_NAME, pageName)
      return {
        [PAGE_VIEW_ID]: newPageViewId, [TIME_SINCE_LAST_EVENT]: 0
      }
    } else {
      const currentTime = new Date()
      timeDifference = timeCalculation(currentTime, eventTime)
      setSessionStorageData(EH_EVENT_TIME, currentTime)
      setSessionStorageData(EH_PAGE_NAME, pageName)
      if (pageName !== currentPageName) {
        // Land on a new page
        // Set a new page view id for subsequent events
        // Set previous page
        setSessionStorageData(EH_PAGE_VIEW_ID, uuidv4())
        setSessionStorageData(EH_PREVIOUS_PAGE_VIEW_ID, pageId)
      }
      // In the reload page scenario we do not generate a new page_view_id.

      return {
        [PAGE_VIEW_ID]: pageId,
        [TIME_SINCE_LAST_EVENT]: timeDifference
      }
    }
  } else {
    // The event is a `track` event

    // The user opened and another tab and went on a different page 👇
    if (pageName !== currentPageName) {
      const newPageId = uuidv4()
      setSessionStorageData(EH_PAGE_NAME, pageName)
      setSessionStorageData(EH_PAGE_VIEW_ID, newPageId)

      const currentTime = new Date()
      timeDifference = timeCalculation(currentTime, eventTime)
      return {
        [PAGE_VIEW_ID]: newPageId,
        [TIME_SINCE_LAST_EVENT]: timeDifference
      }
    }

    const currentTime = new Date()
    timeDifference = timeCalculation(currentTime, eventTime)

    setSessionStorageData(EH_EVENT_TIME, currentTime)
    return {
      [PAGE_VIEW_ID]: pageId,
      [TIME_SINCE_LAST_EVENT]: timeDifference
    }
  }
}

export const timeCalculation = (currentTime, eventTime) => {
  if (isNaN(currentTime.getTime()) || isNaN(eventTime.getTime())) {
    return undefined
  }
  return parseInt((Math.abs(
    currentTime.getTime() -
    eventTime.getTime()) /
    1000).toFixed(1), 10)
}
export { timeOnPage }
