import { pick } from 'ramda'
import { EVENT_FIELDS, EVENT_ORIGIN } from '../../constants/analytics'

export const extractEventData = (payload, application) => {
  const picked = pick(
    [EVENT_FIELDS.EVENT_ID],
    payload.properties
  )

  return {
    // Default event_origin to "client"
    [EVENT_FIELDS.EVENT_ORIGIN]:
      payload.properties[EVENT_FIELDS.EVENT_ORIGIN] || EVENT_ORIGIN.CLIENT,
    ...picked,
    application,
    [EVENT_FIELDS.EVENT_DATETIME]: payload.meta.timestamp,
    [EVENT_FIELDS.EVENT_TYPE]: payload.type
  }
}
