/* eslint-disable node/no-callback-literal */
const analyticsJest = (callback) => {
  if (!callback) {
    return {
      name: 'unit-test-analytics-plugin'
    }
  }

  return {
    identify: () => {
      callback('identify')
    },
    initialize: () => {
      callback('initialize')
    },
    loaded: () => true,
    name: 'unit-test-analytics-plugin',
    page: () => {
      callback('page')
    },
    track: () => {
      callback('track')
    }
  }
}

export { analyticsJest }
