/* eslint-disable camelcase */
import { EVENT_FIELDS } from '../../constants/analytics'

import { hashEmail, hashPhone } from './hashUtils'
const addHashedData = async (applicationData) => {
  const hashedData = {}

  if (applicationData) {
    const { form_email, form_phone } = applicationData

    if (form_email) {
      const hashedEmail =
        await hashEmail(form_email)
      hashedData[EVENT_FIELDS.FORM_EMAIL_HASH] = hashedEmail
    }

    if (form_phone) {
      const hashedPhone =
        await hashPhone(form_phone)
      hashedData[EVENT_FIELDS.FORM_PHONE_HASH] = hashedPhone
    }
  }

  return hashedData
}

export { addHashedData }
