/* eslint-disable no-console */
// This is our custom Freedom Debt Relief Plugin for getanalytics.io

import { EVENT_ACTION } from '../../constants/analytics'
import isLoggerValid from '../utils/isLoggerValid'
import { EVENT_PRODUCER_URL } from 'constants/api'
import { generateFreedomPayload } from '../utils/generateFreedomPayload'

const analyticsFreedom = ({
  trackingUrl,
  userConfig,
  application,
  shouldExtractApplicationData,
  isDebug,
  options = {}
}) => {
  const logger = isLoggerValid(options.logger) ? options.logger : console
  const urlPassedFromClient = trackingUrl || EVENT_PRODUCER_URL

  const pushEvent = async (payload) => {
    if (!urlPassedFromClient) {
      return
    }
    const requestOptions = {
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST'
    }

    try {
      await fetch(urlPassedFromClient, requestOptions)
    } catch (e) {
      if (isDebug) {
        console.error('analytics-freedom: track event failed')
      }
    }
  }

  return {
    config: Object.assign({}, userConfig),
    name: 'analytics-freedom',
    page: async ({ payload }) => {
      const pageName = payload.properties.page_name
      const eventName = `${pageName} - Page Loaded`

      payload.properties = {
        ...payload.properties
      }
      const payloadToSend = await generateFreedomPayload(
        payload,
        eventName,
        EVENT_ACTION.PAGE_VIEW,
        { application, shouldExtractApplicationData }
      )

      if (isDebug) {
        logger.info('ANALYTICS :: freedom :: page - ', eventName, payloadToSend)
      }

      return pushEvent(payloadToSend)
    },
    track: async ({ payload, instance }) => {
      // Track events don't have pageData by default, so we have to merge it in
      const pageDataProperties = instance.getState('page')?.last?.properties

      payload.properties = {
        ...pageDataProperties,
        ...payload.properties
      }
      const pageName = payload.properties.page_name
      const eventName = `${pageName} - ${payload.event}`

      const payloadToSend = await generateFreedomPayload(
        payload,
        eventName,
        payload.properties.event_action,
        { application, shouldExtractApplicationData }
      )

      if (isDebug) {
        logger.info(
          'ANALYTICS :: freedom :: track - ',
          eventName,
          payloadToSend
        )
      }

      return pushEvent(payloadToSend)
    }
  }
}

export { analyticsFreedom, generateFreedomPayload }
