import { waitFor } from './waitFor'
import { parseCookies } from 'utils/cookies'

const UTAG_COOKIE_KEY = 'utag_main'
const UTAG_COOKIE_SESSION_ID_KEY = 'ses_id'
const UTAG_COOKIE_VISITOR_ID_KEY = 'v_id'

const utag = () => window.utag && parseCookies()[UTAG_COOKIE_KEY]
const parseUtagCookie = () => {
  try {
    // The utag_main cookie is a string containing a set of key/values.
    // Example:
    //   v_id:01875d83c812000185569401c51b03075001606d00fb8$_sn:5$_se:9$_ss:0$_st:1688585491461$dc_visit:2$ses_id:1688582946299;exp-session$_pn:5;exp-session
    const utagCookie = parseCookies()[UTAG_COOKIE_KEY] || ''
    const utagCookieObject = {}
    const utagCookieArray = utagCookie.split('$')
    for (let i = 0; i < utagCookieArray.length; i++) {
      const utagCookieKeyVal = utagCookieArray[i].split(':')
      // Some values look like "ses_id:1688582946299;exp-session"
      // so we need to remove the trailing ";exp-session"
      utagCookieObject[utagCookieKeyVal[0]] = utagCookieKeyVal[1].split(';')[0]
    }

    return utagCookieObject
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Event Horizon: There was an unexpected error while parsing the utag_main cookie', error)

    return {}
  }
}

const getTealiumIds = () =>
  // eslint-disable-next-line no-unused-vars
  new Promise((resolve, reject) => {
    waitFor(
      utag,
      () => {
        const tealiumCookie = parseUtagCookie()
        resolve({
          tealium_session_id: tealiumCookie[UTAG_COOKIE_SESSION_ID_KEY],
          tealium_visitor_id: tealiumCookie[UTAG_COOKIE_VISITOR_ID_KEY]
        })
      },
      () => {
        resolve({
          tealium_session_id: undefined,
          tealium_visitor_id: undefined
        })
      }
    )
  })

export { getTealiumIds }
