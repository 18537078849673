import { timeOnPage } from './timeOnPage'

const analyticsTimeOnPage = () => ({
  name: 'analytics-timeOnPage',
  page: ({ payload }) => {
    const pagePath = payload.properties.path
    payload.properties = {
      ...payload.properties,
      ...timeOnPage('page', pagePath)
    }
  },
  track: ({ payload, instance }) => {
    const pageDataProperties = instance.getState('page')?.last?.properties
    // If path is not defined we will rely on window to get path information
    // timeOnPage relies on two parameters, eventType and pagePath
    // Otherwise it returns page_view_id and time_since_last_event as undefined
    const pagePath = payload.properties.path || window.location.pathname
    payload.properties = {
      ...pageDataProperties,
      ...payload.properties,
      ...timeOnPage('track', pagePath)
    }
  }
})

export { analyticsTimeOnPage }
