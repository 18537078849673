import { digestMessage } from './hashUtils'

const lowerCased = (value) => value.trim().toLowerCase()
const US_COUNTRY_CODE = 1

/*
* The formFields object must be of this form
* {
    address: '123 Main Street',
    city: 'FOCO',
    country: 'USA',
    date_of_birth: '01/01/1900',
    email: 'Broccolli.Cottage@gmail.com',
    first_name: 'Broccoli',
    last_name: 'Cottage',
    phone: '(123) 555- 6000', state: 'Colorado',
    zipcode: '80525-2222',
    social_security: '999-99-9999',
    ss_amount: 5700,
    ss_annual_income: 10000,
    ss_co_applicant_income: 'CO Applicant',
    ss_purpose: 'SS Purpose',
  }
*/

export const getFormData = async (formFields) => {
  const freedomPayload = {}
  const tealiumPayload = {}

  const processFields = {
    address: async (formFields) => {
      freedomPayload.address = formFields.address
    },
    city: async (formFields) => {
      const formatted = lowerCased(formFields.city)
      const digested = await digestMessage(formatted)
      freedomPayload.city = formatted
      freedomPayload.hash_city = digested
      tealiumPayload.form_hash_city = digested
    },
    country: async (formFields) => {
      const formatted = lowerCased(formFields.country)
      const digested = await digestMessage(formatted)
      freedomPayload.country = formatted
      freedomPayload.hash_country = digested
      tealiumPayload.form_hash_country = digested
    },
    date_of_birth: async (formFields) => {
      freedomPayload.date_of_birth = formFields.date_of_birth
    },
    email: async (formFields) => {
      const formatted = lowerCased(formFields.email)
      const digested = await digestMessage(formatted)
      freedomPayload.email = formatted
      freedomPayload.hash_email = digested
      tealiumPayload.form_hash_email = digested
    },
    first_name: async (formFields) => {
      const formatted = lowerCased(formFields.first_name)
      const digested = await digestMessage(formatted)
      freedomPayload.first_name = formatted
      freedomPayload.hash_first_name = digested
      tealiumPayload.form_hash_first_name = digested
    },
    last_name: async (formFields) => {
      const formatted = lowerCased(formFields.last_name)
      const digested = await digestMessage(formatted)
      freedomPayload.last_name = formatted
      freedomPayload.hash_last_name = digested
      tealiumPayload.form_hash_last_name = digested
    },
    phone: async (formFields) => {
      const formatted = `${US_COUNTRY_CODE}${formFields.phone.replace(/[^.0-9]/g, '')}`
      const digested = await digestMessage(formatted)
      freedomPayload.phone = formatted
      freedomPayload.hash_phone = digested
      tealiumPayload.form_hash_phone = digested
    },
    social_security: async (formFields) => {
      freedomPayload.social_security = formFields.social_security
    },
    ss_amount: async (formFields) => {
      freedomPayload.ss_amount = formFields.ss_amount
      tealiumPayload.form_ss_amount = formFields.ss_amount
    },
    ss_annual_income: async (formFields) => {
      freedomPayload.ss_annual_income = formFields.ss_annual_income
      tealiumPayload.form_ss_annual_income = formFields.ss_annual_income
    },
    ss_co_applicant_income: async (formFields) => {
      freedomPayload.ss_co_applicant_income = formFields.ss_co_applicant_income
      tealiumPayload.form_ss_co_applicant_income =
        formFields.ss_co_applicant_income
    },
    ss_purpose: async (formFields) => {
      freedomPayload.ss_purpose = formFields.ss_purpose
      tealiumPayload.form_ss_purpose = formFields.ss_purpose
    },
    state: async (formFields) => {
      const formatted = lowerCased(formFields.state)
      const digested = await digestMessage(formatted)
      freedomPayload.state = formatted
      freedomPayload.hash_state = digested
      tealiumPayload.form_hash_state = digested
    },
    zipcode: async (formFields) => {
      const formatted = formFields.zipcode.substring(0, 5)
      const digested = await digestMessage(formatted)
      freedomPayload.zipcode = formatted
      freedomPayload.hash_zipcode = digested
      tealiumPayload.form_hash_zipcode = digested
    }
  }

  for (const key of Object.keys(formFields)) {
    if (processFields[key]) {
      await processFields[key](formFields)
    }
  }
  return { freedomFormData: freedomPayload, tealiumFormData: tealiumPayload }
}
