import UserAgentParser from 'ua-parser-js'
import { EVENT_FIELDS } from '../../constants/analytics'

const extractUserDeviceData = (payload) => {
  const userAgentParser = new UserAgentParser(navigator.userAgent)

  return {
    [EVENT_FIELDS.BROWSER_HEIGHT]: payload.properties.height,
    [EVENT_FIELDS.BROWSER_WIDTH]: payload.properties.width,
    [EVENT_FIELDS.USER_AGENT]: navigator.userAgent,
    [EVENT_FIELDS.USER_AGENT_BROWSER_NAME]: userAgentParser.getBrowser().name,
    [EVENT_FIELDS.USER_AGENT_BROWSER_VERSION]:
      userAgentParser.getBrowser().version,
    [EVENT_FIELDS.USER_AGENT_CPU_ARCHITECTURE]:
      userAgentParser.getCPU().architecture,
    [EVENT_FIELDS.USER_AGENT_DEVICE_MODEL]: userAgentParser.getDevice().model,
    [EVENT_FIELDS.USER_AGENT_DEVICE_TYPE]: userAgentParser.getDevice().type,
    [EVENT_FIELDS.USER_AGENT_DEVICE_VENDOR]: userAgentParser.getDevice().vendor,
    [EVENT_FIELDS.USER_AGENT_ENGINE_NAME]: userAgentParser.getEngine().name,
    [EVENT_FIELDS.USER_AGENT_ENGINE_VERSION]:
      userAgentParser.getEngine().version,
    [EVENT_FIELDS.USER_AGENT_OS_NAME]: userAgentParser.getOS().name,
    [EVENT_FIELDS.USER_AGENT_OS_VERSION]: userAgentParser.getOS().version
  }
}

export { extractUserDeviceData }
