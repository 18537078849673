import { getVisitId } from './visitId'
const analyticsVisitId = () => ({
  name: 'analytics-visitId',
  page: ({ payload }) => {
    const ids = payload.properties.ids || {}
    const allIds = { ...ids, visit_id: getVisitId() }
    payload.properties = {
      ...payload.properties,
      ids: allIds
    }
  },
  track: ({ payload, instance }) => {
    const pageDataProperties = instance.getState('page')?.last?.properties
    const ids = payload.properties.ids || {}
    const allIds = { ...ids, visit_id: getVisitId() }
    payload.properties = {
      ...pageDataProperties,
      ...payload.properties,
      ids: allIds
    }
  }
})

export { analyticsVisitId }
